@import "./colors";

* {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: 'Inter', sans-serif;
}

h2 {
  margin-bottom: 1rem;
  margin-top: 2rem;
}
a {
  font-weight: 600;
  color: $orange;
  &:hover {
    text-decoration: none;
  }
}
.MuiFormHelperText-root {
  a {color: #1A1D2B;}
}
.project-title {
  font-size: 18px;
  font-weight: 600;
  color: $orange;
}
p {
  padding-bottom: .5rem;
}
h4 {
  padding-bottom: 1rem;
}

.text-center {
  text-align: center;
}
.form-item  {
  margin-bottom: 1rem;
}

// ScrollBar
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  background: none;
}

::-webkit-scrollbar-thumb {
  background: rgba(206, 110, 58, 0.534);  
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: $orange;
  
}

.point-crossection-index {
  height: 20px;
  width: 20px;
  font-size: 15px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 50%)
}

.stored-crossection {
  background-color: black;
  color: white;
}

.tmp-crossection {
  background-color: white;
  color: black;
}
.reactour__popover {
  --reactour-accent: #CE6F3A;
  max-width: 636px !important;  
  border-radius: 10px;
  padding: 48px 28px !important;
}

.MuiAlert-filledInfo {
  background-color: #3070FF !important;
}

.SnackbarContainer-top {
  top: 18px !important;
}